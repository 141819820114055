import axios from "axios";

export default {
  name: "ChangePassword",
  components: {},
  data() {
    return {
      email: "",
      show1: false,
      password: "",
      rules: {
        username: [
          (val) => val.length !== 0 || "Email address field is required.",
          (val) => this.validateEmail(val) || "Invalid email format.",
        ],
        password: [
          (val) => val.length !== 0 || "This field is required.",
          (val) => isNaN(val) || "Password should not be numbers alone.",
        ],
      },
      error: {
        switch: false,
        message: "",
      },
      loginBtnText: "Login",
    };
  },
  methods: {
    async manualLogin() {
      try {
        this.loginBtnText = "Please wait...";
        const validate = await this.$refs.loginForm.validateAsync();

        if (!validate) return;

        const result = await axios.post("/auth/login", {
          username: this.email,
          pwd: this.password,
        });

        if (result && result.status === 200) {
          this.$router.push("/dashboard");
        }
      } catch (error) {
        if (this.objectHasOwnProperty(error, "response")) {
          if (this.objectHasOwnProperty(error.response, "status")) {
            if (error.response.status === 403)
              this.showNotifyMessage({
                message: "Username or password is incorrect.",
                type: "danger",
              });
            else if (error.response.status === 500)
              this.showNotifyMessage({
                message: "Something went wrong, please try again.",
                type: "danger",
              });
          }
        }
      } finally {
        this.loginBtnText = "Login";
      }
    },
  },
};
